import { ThemeProvider, createTheme,responsiveFontSizes } from '@mui/material/styles';


const TemaGlobals = createTheme({
    get components() {
      return {
        MuiButton: {
          styleOverrides: {
            root: ({ownerState}) => ({
              '&:hover': {
                backgroundColor: this.palette[ownerState.color]?.light,
                color: this.palette[ownerState.color]?.color
              }
            })
          }
        }
      }
  },
  palette: {
    primary: {
      main:'#022162',
      light: '#0235A1',
      color: '#ffffff'
    },
    textColorTitle:"#424242",
    textColorTitle2:"#eeeeee",
    backgroundColorPage:"#f1f1f1",
    principalColor:"#022162",
    azul:"#2962ff"
  }
});

export const TemaGlobal = responsiveFontSizes(TemaGlobals)