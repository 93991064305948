import { useEffect, useRef } from "react";


const noop = () => {}


const useClickOutside = (
    wrapper,
    handler = noop,
    dependencies
) => {

    const callbackRef = useRef(handler)
    const outsideClickHandler = (e) => {
        
        if (wrapper && !wrapper?.current.contains(e.target))
        {
            callbackRef.current(e)
        }
    }

    // useEffect wrapper to be safe for concurrent mode
    useEffect(() => {
        callbackRef.current = handler
    })

    useEffect(() => {
        document?.addEventListener('click', outsideClickHandler)
        return () => document?.removeEventListener('click', outsideClickHandler)
    }, dependencies)

}

export default useClickOutside;