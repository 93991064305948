


//export const URL_SERVER = process.env.NODE_ENV = 'production'?process.env.REACT_APP_API_URL:process.env.REACT_APP_API_LOCAL_URL
export const URL_SERVER = process.env.REACT_APP_API_URL
//export const URL_SERVER = process.env.REACT_APP_API_LOCAL_URL

//export const KEY_UI = process.env.REACT_APP_KEY_UI
export const VALIDE_USER = "valideLogin"
export const NAME_USER = "userName"
export const ID_USER = "userId"
export const PHONE_USER = "userPhone"
export const CODE_USER = "userCode"
export const LOGIN_SPINNER = "loginSpinner"
export const RESP_ERROR_LOGIN = "errorResponseLogin"     
export const ERROR_USER = "userError" 
export const TYPE_USER = "typeUser"   
export const ACCIONES = "acciones"   
export const arrayMaster = ['Master_GNOB','Master_FINANCIADO','Master_VIP']
export const arrayAdmin = ['Atencion_al_cliente','Gestor','super_admin']
export const arrayAdminView = ['super_admin']
export const TOKEN = "token" 
export const TITLEPAGE = "titlePage"
export const SALDO_EFECTIVO = "saldo_efectivo" 
export const SALDO = "saldo" 
export const INTERES_G = "interes_g" 
export const INTERES_S = "interes_s" 
export const IVA = "iva"  


export const DigiPhone = [
    '222',
    '333',
    '555',
    '551',
    '666',
    '550'
]   







